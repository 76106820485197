<template>
  <div class="p-4">
    <div class="login-box">

      <div class="logo">
        <img src="../../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="title">
          <button @click="toBack" class="back">
            <i class="fa-regular fa-angle-right"></i>
          </button>
          <h2>اعتبارسنجی</h2>
        </div>


        <div class="input">
          <label for="">کد تایید ارسال شده به شماره خود را وارد کنید:</label>
          <div class="input-group-cus">
            <input
                v-for="(value, index) in inputs"
                :key="index"
                :id="'inp-'+index"
                v-model="inputs[index]"
                @input="handleInput($event, index)"
                @keydown.backspace="handleBackspace($event, index)"
                maxlength="1"
                type="text"
                class="input-box"
            />

          </div>
          <button v-if="!timeEnd" class="code-again"><em>{{ formattedTime }}</em> مانده تا دریافت مجدد کد</button>
          <button v-else @click="startTimer" class="code-again2">دریافت مجدد کد</button>
        </div>

        <div class="btns">

          <v-btn @click="setOtp" :loading="loading" class="brn btn-primary">ادامه</v-btn>
        </div>


      </div>

      <img class="bg" src="../../assets/img/half-circle.png" alt="">

    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          <i class="fa-regular fa-close"></i>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import Cookies from 'js-cookie';

export default {
  name: "OtpView",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      otp: '',
      textSnack: false,
      colorSnack: '',
      inputs: ["", "", "", "", ""],
      timeEnd: false,
      intervalId: null,
      timeRemaining: '',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    }
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  methods: {
    toBack() {
      this.$router.push('/auth/register')
    },
    getDashboard(token, url) {

      window.location.href = `${url}/auth/check-login?token=${encodeURIComponent(token)}`

    },
    sendOtp() {
      this.inputs = ["", "", "", "", ""]
      let mobile = store.getters.getMobile
      this.loading = true
      let body = {
        phone_number: mobile,
      }
      axios.post(this.apiUrl + 'api/otp', body, {headers: this.headers})
          .then(() => {
            this.loading = false

          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.showMsg('مشکلی پیش آمده', 'red')

          })

    },
    setOtp() {

      let mobile = store.getters.getMobile
      if (this.inputs.join('').length < 5) {
        this.showMsg('لطفا کد را به درستی وارد کنید', 'red')
        return
      }
      this.loading = true
      let body = {
        phone_number: mobile,
        otp: this.inputs.join(''),
      }
      axios.post(this.apiUrl + 'api/otp/verify', body,
          {headers: this.headers})
          .then(res => {
            this.loading = false
            if (res.data.status === 200) {
              if (res?.data?.api_token != undefined) {
                Cookies.set('token', res.data.api_token, {path: '/',});
                localStorage.setItem('urlPanel', res.data.url)
                this.getDashboard(res.data.api_token, res.data.url)
                return;

              }

              let token = res.data.access_token
              this.setCookie(token)
              store.commit('setLogin', token)
              this.$router.push('/auth/organizational-information')

            }
            if (res.data.status === 400) {
              this.showMsg('کد وارد شده صحیح نیست!', 'red')
              return
            }
            if (res.data.status === 409) {
              this.showMsg('قبلا ثبت نام شده اید', 'red')
              return
            }

          })
          .catch(err => {
            console.log(err)
            this.loading = false
            if (err.response.status === 400) {
              this.showMsg('کد وارد شده صحیح نیست', 'red')
              this.inputs = ["", "", "", "", ""]
              document.getElementById("inp-0").focus();
              return
            }
            this.showMsg('مشکلی پیش آمده', 'red')

          })
    },
    setCookie(token) {
      Cookies.set('token', token, {expires: 7, path: '/'});
    },
    getCookie() {
      return Cookies.get('token');
    },
    deleteCookie() {
      Cookies.remove('token');
    },
    onlyNumber() {
      this.mobile = this.mobile.replace(/[^0-9]/g, '');

    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },
    startTimer() {
      this.timeRemaining = 120
      const endTime = localStorage.getItem("timerEndTime");
      this.timeEnd = false
      console.log(endTime)

      if (endTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeLeft = parseInt(endTime, 10) - currentTime;
        this.timeRemaining = timeLeft > 0 ? timeLeft : 0;
      } else {
        const timerEndTime = Math.floor(Date.now() / 1000) + this.timeRemaining;
        localStorage.setItem("timerEndTime", timerEndTime);
        this.sendOtp()
      }

      this.intervalId = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          clearInterval(this.intervalId);
          this.onTimerEnd();
        }
      }, 1000);

    },
    onTimerEnd() {

      localStorage.removeItem("timerEndTime");
      this.timeEnd = true

    },
    handleInput(event, index) {
      const value = event.target.value;
      if (!/^\d$/.test(value)) {
        this.inputs[index] = "";
        return;
      }
      if (index > 3) {
        this.setOtp()

      }

      if (index < this.inputs.length - 1) {
        this.$nextTick(() => {
          const nextInput = event.target.nextElementSibling;
          if (nextInput) nextInput.focus();
        });
      }

    },
    handleBackspace(event, index) {

      if (!this.inputs[index] && index > 0) {
        this.$nextTick(() => {
          const prevInput = event.target.previousElementSibling;
          if (prevInput) prevInput.focus();
        });
      }

    },
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  mounted() {
    let mobile = store.getters.getMobile
    if (mobile === '') {
      this.$router.push('/auth/register')
    }

    this.startTimer();

  }
}
</script>

<style scoped>
.code-again, .code-again2 {
  width: 100%;
  text-align: center;
  color: #344054;
  margin: 10px 0;
  font-size: 12px;
  cursor: default;
}

.code-again2 {
  color: #0A75D8;
  cursor: pointer;
}

</style>
