<template>
  <div class="p-4">
    <div class="completed-box w-100 text-center">
      <a :href="link" class="btn btn-primary mx-auto">ورود به داشبورد</a>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: "completedComponent",
  data() {
    return {
      apiToken: '',
      url: '',
      link: '',
    }
  },
  mounted() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      let url = localStorage.getItem('urlPanel')
      let token = Cookies.get('token')
      if (token === undefined) {
        this.link = `${url}/auth/login`
      } else {
        this.link = `${url}/auth/check-login?token=${encodeURIComponent(token)}`
      }

    }
  }
}
</script>

<style scoped>

</style>
