<template>
  <div class="p-4">
    <div class="register-box">

      <div class="logo">
        <img src="../../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="title">
          <h2 class="w-100">ثبت نام</h2>
          <span>به اجیستنت خوش آمدید.</span>
        </div>

        <form class="form" @submit.prevent="register()">
          <div class="input">
            <label>شماره موبایل</label>
            <input v-model="mobile" @input="onlyNumber" class="text-left direction-ltr" type="text"
                   placeholder="0912 1234567"/>
          </div>
          <div class="btns pt-3">

            <v-btn :loading="loading" class="brn btn-primary" type="submit">ثبت نام</v-btn>
<!--
            <span>قبلا اکانت ساخته اید؟  <router-link to="/auth/login">ورود</router-link></span>
-->

          </div>
        </form>

      </div>

      <img class="bg" src="../../assets/img/half-circle.png" alt="">

    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          <i class="fa-regular fa-close"></i>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import store from "../../store";

export default {
  name: "RegisterView",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      mobile: '',
      snackbar: false,
      textSnack: false,
      colorSnack: '',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }
  },
  methods: {

    register() {

      if(this.mobile.length !== 11){
        this.showMsg('شماره موبایل را به درستی وارد کنید!', 'red')
        return
      }


      let timeLocalstorage = localStorage.getItem("timerEndTime")

      const currentTime = Math.floor(Date.now() / 1000);
      const timeLeft = parseInt(timeLocalstorage, 10) - currentTime;

      if (timeLocalstorage !== null && timeLeft <= 0) {
        localStorage.removeItem("timerEndTime")
        store.commit('setMobile', this.mobile)
      }else{
        store.commit('setMobile', this.mobile)
      }
      this.$router.push('/auth/otp')

    },

    onlyNumber() {
      this.mobile = this.mobile.replace(/[^0-9]/g, '');

    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },
  }
}
</script>

<style scoped>

</style>
